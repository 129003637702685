<template>
  <q-form
    @submit="onSubmit"
    class="q-gutter-md"
  >
    <q-input
      dense
      outlined
      readonly
      type="text"
      v-model="destination"
      class="q-mb-lg"
    />

    <q-input
      dense
      outlined
      no-error-icon
      v-model="credentials.code"

      autocorrect="off"
      autocapitalize="off"
      autocomplete="off"
      :spellcheck="false"
      hide-bottom-space

      placeholder="Código"
      mask="######"
      ref="code"
      type="text"
      :rules="[
        () => $v.credentials.code.required || 'Código é obrigatório',
        () => $v.credentials.code.minLength || 'Código inválido'
      ]"
    >
      <template #append>
        <q-icon
          size="xs"
          name="mdi-close"
          class="cursor-pointer"
          v-show="credentials.code"
          @click="credentials.code = ''"
        />
      </template>

      <template #prepend>
        <q-icon
          name="mdi-shield-key-outline"
          size="xs"
        />
      </template>
    </q-input>

    <div class="row items-center justify-between">
      <q-btn
        flat
        dense
        no-caps
        padding="0"
        :ripple="false"
        color="primary"
        label="Voltar"
        :to="authLoginRoute"
      />

      <AuthSubmit
        label="Confirmar"
        :loading="auth.loading"
      />
    </div>
  </q-form>
</template>

<script>
import { mapState } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'
import { ROUTE_AUTH_SIGN_IN, ACTION_AUTH_MFA } from '@/modules/auth/constants'
import AuthSubmit from '../components/AuthSubmit'

export default {
  components: {
    AuthSubmit
  },

  data () {
    return {
      credentials: {},
      authLoginRoute: { name: ROUTE_AUTH_SIGN_IN }
    }
  },

  mounted () {
    this.$nextTick().then(() => (
      this.$refs.code.focus()
    ))
  },

  computed: {
    ...mapState({
      auth: ({ authStore }) => authStore
    }),

    destination () {
      const details = this.auth?.cognitoUser?.challengeParam ?? {}
      const delivery = details.CODE_DELIVERY_DELIVERY_MEDIUM ? `${details.CODE_DELIVERY_DELIVERY_MEDIUM}:` : ''
      const destination = details.CODE_DELIVERY_DESTINATION ?? this.$route.query.email
      return destination ? `${delivery} ${destination}` : ''
    }
  },

  validations: {
    credentials: {
      code: { required, minLength: minLength(6) }
    }
  },

  methods: {
    onSubmit () {
      if (this.auth.loading) return
      this.$store.dispatch(ACTION_AUTH_MFA, this.credentials.code)
    }
  }
}
</script>
